import { staticImplementsDecorator } from '@mkx/decorators/static-implements.decorator';
import { ApiResponseEntityBuilderInterface } from '@mkx/interfaces/common/api-response-entity-builder.interface';
import { Expose, plainToInstance } from 'class-transformer';
import 'reflect-metadata';

@staticImplementsDecorator<ApiResponseEntityBuilderInterface>()
export class BillingModel {
  @Expose({ groups: ['get', 'post'], name: 'id' })
  private readonly _id: string;

  @Expose({ groups: ['get', 'post', 'put'], name: 'billing_contract' })
  private readonly _billingContract: string;

  @Expose({
    groups: ['get', 'post', 'put'],
    name: 'billing_technology_fee_mode',
  })
  private readonly _billingTechnologyFeeMode: string | null;

  @Expose({ groups: ['get', 'post', 'put'], name: 'billing_technology_fee' })
  private readonly _billingTechnologyFee: number | null;

  @Expose({ groups: ['get', 'post', 'put'], name: 'revenue_share' })
  private readonly _revenueShare: number | null;

  @Expose({ groups: ['get', 'post', 'put'], name: 'network' })
  private readonly _network: string | null;

  constructor(
    id: string | null,
    billingContract: string,
    billingTechnologyFeeMode: string | null,
    network: string | null,
    billingTechnologyFee: number | null,
    revenueShare: number | null
  ) {
    this._id = id;
    this._billingContract = billingContract;
    this._billingTechnologyFeeMode = billingTechnologyFeeMode;
    this._billingTechnologyFee = billingTechnologyFee;
    this._revenueShare = revenueShare;
    this._network = network;
  }

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(BillingModel, body, {
      groups: ['get'],
    });
  }

  public get id(): string {
    return this._id;
  }

  public get billingContract(): string {
    return this._billingContract;
  }

  public get billingTechnologyFeeMode(): string {
    return this._billingTechnologyFeeMode;
  }

  public get billingTechnologyFee(): number | null {
    return this._billingTechnologyFee;
  }

  public get revenueShare(): number | null {
    return this._revenueShare;
  }

  public get network(): string | null {
    return this._network;
  }
}
