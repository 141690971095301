import { ICON_TYPE, IconModel } from './icon.model';
import { Expose, Exclude, plainToInstance, Type } from 'class-transformer';
import 'reflect-metadata';
import { staticImplementsDecorator } from '@mkx/decorators/static-implements.decorator';
import { ApiResponseEntityBuilderInterface } from '@mkx/interfaces/common/api-response-entity-builder.interface';
import { SelectOptionInterface } from '@mkx/interfaces/common/select-option.interface';
import { CONTRACTS } from './contracts.model';
import { ACCOUNT_TYPES } from '@mkx/constants/account-types';
import { BasicSelectOptionModel } from '@mkx/models/basic-select-option.model';
import { IndustryModel } from '@mkx/features/industries/models/industry.model';
import { NetworkAccountModel } from '@mkx/features/network-accounts/models/network-account.model';
import { BILLING_CONTRACT_APPLIED_ON_TYPES } from '@mkx/constants/billing-contract-applied-on-types';
import { OPERATIONAL_CONTRACT_TYPES } from '@mkx/constants/operational-contract-types';
import { translate } from '@ngneat/transloco';
import { OperationalContractModel } from '@mkx/features/operational-contract/models/operational-contract.model';
import { BillingModel } from '@mkx/models/billing.model';

@staticImplementsDecorator<ApiResponseEntityBuilderInterface>()
export class AccountModel implements SelectOptionInterface<AccountModel> {
  @Exclude()
  private selected = false;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'type' })
  private type: string;

  @Expose({ groups: ['read', 'get'], name: 'id' })
  private id: number;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'name' })
  private name: string;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'company' })
  private companyName: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'display_currency' })
  private displayCurrency: string;

  @Type(() => BasicSelectOptionModel)
  @Expose({ groups: ['read', 'get'], name: 'time_zone' })
  private timezone: BasicSelectOptionModel;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'company_url' })
  private companyUrl: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'app_url' })
  private appUrl: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'mail_domain' })
  private mailDomain: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'mail_from' })
  private mailFrom: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'status_url' })
  private statusUrl: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'contract' })
  private contract: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'over_delivery' })
  private overDelivery: number | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'revenue_share' })
  private revenueShare: number | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'fixed_margin' })
  private fixedMargin: number | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'markup' })
  private markup: number | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'payment_conditions',
  })
  private paymentConditions: number | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'generate_credit_note',
  })
  private generateCreditNote: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'international_client',
  })
  private internationalClient: boolean;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'invoice_mode' })
  private invoiceMode: string | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'enable_invoice_thresholds',
  })
  private enableInvoiceThresholds: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'break_down_campaigns_invoice',
  })
  private breakDownCampaignsInvoice: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'billing_address_from',
  })
  private billingAddressFrom: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'billing_cif_from' })
  private billingCifFrom: string | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'billing_company_from',
  })
  private billingCompanyFrom: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'bank_iban' })
  private bankIban: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'bank_name' })
  private bankName: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'bank_swift_bic' })
  private bankSwiftBic: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'billing_address' })
  private billingAddress: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'billing_cif' })
  private billingCif: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'billing_company' })
  private billingCompany: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'billing_contract' })
  private billingContract: string | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'billing_technology_fee',
  })
  private billingTechnologyFee: number | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'billing_technology_fee_mode',
  })
  private billingTechnologyFeeMode: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'billing_currency' })
  private billingCurrency: string | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'can_create_campaigns',
  })
  private canCreateCampaigns: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'can_link_reporting_only_campaigns',
  })
  private _canLinkReportingOnlyCampaigns: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'can_create_campaigns_outside_primary_contract',
  })
  private _canCreateCampaignsOutsidePrimaryContract: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'can_see_trader_view',
  })
  private canSeeTraderView: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'can_update_campaigns',
  })
  private canUpdateCampaigns: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'include_logo_reporting',
  })
  private includeLogoReporting: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'dark_background_logo',
  })
  private darkBackgroundLogo: string | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'clear_background_logo',
  })
  private clearBackgroundLogo: string | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'use_as_platform_logo',
  })
  private useAsPlatformLogo: boolean;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'primary_colour' })
  private primaryColour: string | null;

  @Expose({ groups: ['read', 'get', 'post', 'put'], name: 'secondary_colour' })
  private secondaryColour: string | null;

  @Type(() => IndustryModel)
  @Expose({ groups: ['read', 'get'], name: 'industry' })
  private industry: IndustryModel | null;

  @Type(() => AccountModel)
  @Expose({ groups: ['read', 'get'], name: 'account' })
  private account: AccountModel | null;

  @Type(() => NetworkAccountModel)
  @Expose({ groups: ['read', 'get'], name: 'network_accounts' })
  private _networkAccounts: NetworkAccountModel[] | null;

  @Type(() => BillingModel)
  @Expose({ groups: ['post', 'read', 'get'], name: 'billing' })
  private _billing: BillingModel[] | null;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'propagate_billing',
  })
  private _propagateBilling: boolean;

  @Expose({
    groups: ['read', 'get', 'post', 'put'],
    name: 'show_footer_powered_by',
  })
  private _showFooterPoweredBy: boolean;

  @Expose({ groups: ['post', 'get', 'put'], name: 'custom_report_key' })
  private _customReportKey: string;

  @Expose({ groups: ['post', 'get', 'put'], name: 'free_trial' })
  private _freeTrial: boolean;

  @Expose({ groups: ['post', 'get', 'put'], name: 'notes' })
  private _notes: string;

  constructor(
    type: ACCOUNT_TYPES,
    name: string,
    displayCurrency: BasicSelectOptionModel | null = null,
    timezone: BasicSelectOptionModel | null = null,
    companyName: string | null = null,
    companyUrl: string | null = null,
    appUrl: string | null = null,
    mailDomain: string | null = null,
    mailFrom: string | null = null,
    statusUrl: string | null = null,
    contract: BasicSelectOptionModel | OperationalContractModel | null = null,
    overDelivery: number | null = null,
    revenueShare: number | null = null,
    fixedMargin: number | null = null,
    markup: number | null = null,
    paymentConditions: number | null = null,
    generateCreditNote = false,
    internationalClient = false,
    invoiceMode: BasicSelectOptionModel | null = null,
    enableInvoiceThresholds = false,
    breakDownCampaignsInvoice = false,
    billingAddressFrom: string | null = null,
    billingCifFrom: string | null = null,
    billingCompanyFrom: string | null = null,
    bankIban: string | null = null,
    bankName: string | null = null,
    bankSwiftBic: string | null = null,
    billingAddress: string | null = null,
    billingCif: string | null = null,
    billingCompany: string | null = null,
    billingContract: BasicSelectOptionModel | null = null,
    billingTechnologyFee: number | null = null,
    billingTechnologyFeeMode: BasicSelectOptionModel | null = null,
    billingCurrency: BasicSelectOptionModel | null = null,
    canCreateCampaigns = false,
    canLinkReportingOnlyCampaigns = false,
    canCreateCampaignsOutsidePrimaryContract = false,
    canSeeTraderView = false,
    canUpdateCampaigns = false,
    includeLogoReporting = false,
    darkBackgroundLogo: string | null = null,
    clearBackgroundLogo: string | null = null,
    useAsPlatformLogo = false,
    primaryColour: string | null = null,
    secondaryColour: string | null = null,
    showFooterPoweredBy = false,
    industry: IndustryModel | null = null,
    account: AccountModel | null = null,
    networkAccounts: NetworkAccountModel[] | null = null,
    customReportKey: string | null = null,
    freeTrial = false,
    billing: BillingModel[] | null = null,
    propagateBilling = false,
    notes: string | null = null
  ) {
    this.type = type;
    this.name = name;
    this.companyName = companyName;
    this.displayCurrency = displayCurrency
      ? displayCurrency.selectOptionGetIdentifier()
      : null;
    this.timezone = timezone;
    this.companyUrl = companyUrl;
    this.appUrl = appUrl;
    this.mailDomain = mailDomain;
    this.mailFrom = mailFrom;
    this.statusUrl = statusUrl;
    this.contract = contract ? contract.selectOptionGetIdentifier() : null;
    this.overDelivery = overDelivery;
    this.revenueShare = revenueShare;
    this.fixedMargin = fixedMargin;
    this.markup = markup;
    this.paymentConditions = paymentConditions;
    this.generateCreditNote = generateCreditNote;
    this.internationalClient = internationalClient;
    this.invoiceMode = invoiceMode
      ? invoiceMode.selectOptionGetIdentifier()
      : null;
    this.enableInvoiceThresholds = enableInvoiceThresholds;
    this.breakDownCampaignsInvoice = breakDownCampaignsInvoice;
    this.billingAddressFrom = billingAddressFrom;
    this.billingCifFrom = billingCifFrom;
    this.billingCompanyFrom = billingCompanyFrom;
    this.bankIban = bankIban;
    this.bankName = bankName;
    this.bankSwiftBic = bankSwiftBic;
    this.billingAddress = billingAddress;
    this.billingCif = billingCif;
    this.billingCompany = billingCompany;
    this.billingContract = billingContract
      ? billingContract.selectOptionGetIdentifier()
      : null;
    this.billingTechnologyFee = billingTechnologyFee;
    this.billingTechnologyFeeMode = billingTechnologyFeeMode
      ? billingTechnologyFeeMode.selectOptionGetIdentifier()
      : null;
    this.billingCurrency = billingCurrency
      ? billingCurrency.selectOptionGetIdentifier()
      : null;
    this.canCreateCampaigns = canCreateCampaigns;
    this._canLinkReportingOnlyCampaigns = canLinkReportingOnlyCampaigns;
    this._canCreateCampaignsOutsidePrimaryContract =
      canCreateCampaignsOutsidePrimaryContract;
    this.canSeeTraderView = canSeeTraderView;
    this.canUpdateCampaigns = canUpdateCampaigns;
    this.includeLogoReporting = includeLogoReporting;
    this.darkBackgroundLogo = darkBackgroundLogo;
    this.clearBackgroundLogo = clearBackgroundLogo;
    this.useAsPlatformLogo = useAsPlatformLogo;
    this.primaryColour = primaryColour;
    this.secondaryColour = secondaryColour;
    this.industry = industry;
    this.account = account;
    this._networkAccounts = networkAccounts;
    this._showFooterPoweredBy = showFooterPoweredBy;
    this._customReportKey = customReportKey;
    this._freeTrial = freeTrial;
    this._billing = billing;
    this.propagateBilling = propagateBilling;
    this._notes = notes;
  }

  static buildFromAPIResponse(body: any): any {
    return plainToInstance(AccountModel, body, { groups: ['read', 'get'] });
  }

  @Expose({ groups: ['post', 'put'], name: 'time_zone' })
  public getTimezoneId(): { id: any } | null {
    return this.timezone ? { id: this.timezone.getId() } : null;
  }

  @Expose({ groups: ['post', 'put'], name: 'industry' })
  public getIndustryId(): { id: any } | null {
    return this.industry ? { id: this.industry.getId() } : null;
  }

  @Expose({ groups: ['post', 'put'], name: 'account' })
  public getAccountId(): { id: any } | null {
    return this.account ? { id: this.account.getId() } : null;
  }

  @Expose({ groups: ['post', 'put'], name: 'network_accounts' })
  public getNetworkAccountsIds(): { id: any }[] | null {
    let result = null;

    if (this._networkAccounts) {
      result = this._networkAccounts.map(networkAccount => {
        return { id: networkAccount.getId() };
      });
    }

    return result;
  }

  public get networkAccounts(): NetworkAccountModel[] | null {
    return this._networkAccounts;
  }

  public set networkAccounts(networkAccounts: NetworkAccountModel[]) {
    this._networkAccounts = networkAccounts;
  }

  public get billing(): BillingModel[] | null {
    return this._billing;
  }

  public set billing(billings: BillingModel[]) {
    this._billing = billings;
  }

  public get propagateBilling(): boolean {
    return this._propagateBilling;
  }

  public set propagateBilling(propagateBilling: boolean) {
    this._propagateBilling = propagateBilling;
  }

  public get hasAnyNetworkConfigured(): boolean {
    return this._networkAccounts !== null;
  }

  public getId(): number {
    return this.id;
  }

  public getName(): string {
    return this.name;
  }

  public getDisplayCurrency(): string {
    return this.displayCurrency;
  }

  public getIndustry(): IndustryModel | null {
    return this.industry;
  }

  public getAccount(): AccountModel | null {
    return this.account;
  }

  public getTimezone(): BasicSelectOptionModel {
    return this.timezone;
  }

  public getCompanyName(): string | null {
    return this.companyName;
  }

  public getAppUrl(): string | null {
    return this.appUrl;
  }

  public getCompanyUrl(): string | null {
    return this.companyUrl;
  }

  public getStatusUrl(): string | null {
    return this.statusUrl;
  }

  public getMailDomain(): string | null {
    return this.mailDomain;
  }

  public getMailFrom(): string | null {
    return this.mailFrom;
  }

  public getOverDelivery(): number | null {
    return this.overDelivery;
  }

  public getMarkup(): number | null {
    return this.markup;
  }

  public getFixedMargin(): number | null {
    return this.fixedMargin;
  }

  public getOperationalContractId(): string | null {
    return this.contract;
  }

  getPrettyOperationalContract(): string {
    if (this.contract === OPERATIONAL_CONTRACT_TYPES.FEE) {
      return 'operational_contract.optimize_budget';
    }

    return this.contract === 'revenue_share'
      ? 'operational_contract.maximize_margin'
      : 'operational_contract.fixed_margin';
  }

  public getBillingContractId(): string | null {
    return this.billingContract;
  }

  public get isBillingMissing(): boolean {
    const isBillingMissing = this.getBillingContractId() === null;
    return (
      this.isAdvertiser() && isBillingMissing && this.hasAnyNetworkConfigured
    );
  }

  public getBillingFeePercentage(): number | null {
    return this.billingTechnologyFee;
  }

  public getBillingFeePercentageBased1(): number | null {
    return this.billingTechnologyFee / 100 + 1;
  }

  public getBillingRevenueSharePercentage(): number | null {
    return this.revenueShare;
  }

  public getBillingTechnologyFeeMode(): string | null {
    return this.billingTechnologyFeeMode;
  }

  public isBillingTechnologyFeeModeMediaCost(): boolean {
    return (
      this.billingTechnologyFeeMode ===
      BILLING_CONTRACT_APPLIED_ON_TYPES.MEDIA_COST
    );
  }

  public isBillingTechnologyFeeModeInvestment(): boolean {
    return (
      this.billingTechnologyFeeMode ===
      BILLING_CONTRACT_APPLIED_ON_TYPES.INVESTMENT
    );
  }

  public isRevenueShare(): boolean {
    return this.getBillingContractId() === CONTRACTS.REVENUE_SHARE;
  }

  public isFee(): boolean {
    return this.getBillingContractId() === CONTRACTS.FEE;
  }

  public shouldShowWizardBudgetField(): boolean {
    return (
      this.getBillingContractId() === CONTRACTS.FEE &&
      this.getOperationalContractId() === CONTRACTS.FEE &&
      this.isBillingTechnologyFeeModeMediaCost()
    );
  }

  public getBillingInvoiceMode(): string | null {
    return this.invoiceMode;
  }

  public getBillingCurrency(): string | null {
    return this.billingCurrency;
  }

  public getBillingEnableInvoiceThresholds(): boolean {
    return this.enableInvoiceThresholds;
  }

  public getBillingGenerateCreditNote(): boolean {
    return this.generateCreditNote;
  }

  public getBillingBreakDownCampaignsInvoice(): boolean {
    return this.breakDownCampaignsInvoice;
  }

  public getBillingCompanyFrom(): string | null {
    return this.billingCompanyFrom;
  }

  public getBillingAddressFrom(): string | null {
    return this.billingAddressFrom;
  }

  public getBillingCifFrom(): string | null {
    return this.billingCifFrom;
  }

  public getBillingBankNameFrom(): string | null {
    return this.bankName;
  }

  public getBillingBankIbanFrom(): string | null {
    return this.bankIban;
  }

  public getBillingBankSwiftBicFrom(): string | null {
    return this.bankSwiftBic;
  }

  public getBillingCompany(): string | null {
    return this.billingCompany;
  }

  public getBillingAddress(): string | null {
    return this.billingAddress;
  }

  public getBillingCif(): string | null {
    return this.billingCif;
  }

  public getBillingPaymentConditions(): number | null {
    return this.paymentConditions;
  }

  public getBillingInternationalClient(): boolean {
    return this.internationalClient;
  }

  public getCanSeeTraderView(): boolean {
    return this.canSeeTraderView;
  }

  public getCanCreateCampaigns(): boolean {
    return this.canCreateCampaigns;
  }

  public get canLinkReportingOnlyCampaigns(): boolean {
    return this._canLinkReportingOnlyCampaigns;
  }

  public get canCreateCampaignsOutsidePrimaryContract(): boolean {
    return this._canCreateCampaignsOutsidePrimaryContract;
  }

  public getCanUpdateCampaigns(): boolean {
    return this.canUpdateCampaigns;
  }

  public getIncludeLogoReporting(): boolean {
    return this.includeLogoReporting;
  }

  public getUseAsPlatformLogo(): boolean {
    return this.useAsPlatformLogo;
  }

  public getDarkBackgroundLogo(): string | null {
    return this.darkBackgroundLogo;
  }

  public getClearBackgroundLogo(): string | null {
    return this.clearBackgroundLogo;
  }

  public getPrimaryColour(): string | null {
    return this.primaryColour;
  }

  public getSecondaryColour(): string | null {
    return this.secondaryColour;
  }

  public hasGoalBid(): boolean {
    return this.contract === CONTRACTS.REVENUE_SHARE;
  }

  public isAdvertiser(): boolean {
    return this.type === ACCOUNT_TYPES.ADVERTISER;
  }

  public isAgency(): boolean {
    return this.type === ACCOUNT_TYPES.AGENCY;
  }

  public getType(): string {
    return this.type;
  }

  public get showFooterPoweredBy(): boolean {
    return this._showFooterPoweredBy;
  }

  public getShowFooterPoweredBy(): boolean {
    return this.showFooterPoweredBy;
  }

  public selectOptionGetValue(): AccountModel {
    return this;
  }

  public selectOptionGetLabel(): string {
    return this.name;
  }

  public selectOptionGetLabelProperty(): string {
    return 'name';
  }

  public selectOptionIsDisabled(): boolean {
    return false;
  }

  public selectOptionSelect() {
    this.selected = true;
  }

  public selectOptionDeselect() {
    this.selected = false;
  }

  public selectOptionIsSelected(): boolean {
    return this.selected;
  }

  public selectOptionGetIdentifier(): any {
    return this.id;
  }

  public selectOptionGetIcon(): IconModel | null {
    let icon = new IconModel('briefcase', ICON_TYPE.ICON);

    if (this.isAgency()) {
      icon = new IconModel('office-building', ICON_TYPE.ICON);
    }

    return this.clearBackgroundLogo !== null
      ? new IconModel(this.clearBackgroundLogo, ICON_TYPE.EXTERNAL)
      : icon;
  }

  public selectOptionGetColorClass(): string | null {
    return null;
  }

  public selectOptionGetDescription(): string | null {
    let description = translate('form.' + this.type);
    if (this.isAdvertiser() && this.account !== null) {
      description = `${description} - ${this.account.name}`;
    }
    return description;
  }

  public selectOptionLabelIsUrl(): boolean {
    return false;
  }

  public getCustomReportKey(): string | null {
    return this._customReportKey;
  }

  public getNotes(): string | null {
    return this._notes;
  }

  public getFreeTrial(): boolean {
    return this._freeTrial;
  }
}
