import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { Router } from '@angular/router';
import { JwtService } from '@mkx/services/jwt.service';
import { environment } from '@mkx/environments/environment';
import { ErrorHandlerService } from '@mkx/services/error-handler.service';
import { APP_ENDPOINTS } from '@mkx/configs/app-endpoints';
import { APP_ROUTES } from '@mkx/configs/app-routes';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { UserProfileGetService } from '@mkx/features/user-profile/services/get/user-profile-get.service';
import { AppearanceService } from '@mkx/services/appearance/appearance.service';
import { UserModel } from '@mkx/features/users/models/user.model';
import { RefreshTokenService } from '@mkx/services/refresh-token.service';
import { TokenModel } from '@mkx/features/user-profile/models/token.model';
import { TokenPostService } from '@mkx/features/user-profile/services/post/token-post.service';
import { SentryScopeService } from '@mkx/services/sentry-scope.service';
import { UserPutService } from '@mkx/features/users/services/put/user-put.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  set redirectUrl(value: string) {
    if (value && !value.includes('login') && !value.includes('maintenance')) {
      this._redirectUrl = decodeURIComponent(value);
    }
  }

  get redirectUrl() {
    return this._redirectUrl;
  }

  private _redirectUrl: string;
  token: string;

  private _loggedUserSubject = new BehaviorSubject<UserModel>(null);
  public get loggedUserSubject(): Observable<UserModel> {
    return this._loggedUserSubject
      .asObservable()
      .pipe(filter(user => user !== null));
  }

  constructor(
    private jwtService: JwtService,
    private tokenPostService: TokenPostService,
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private translate: TranslocoService,
    @Inject(APP_ENDPOINTS) private appEndpoints,
    @Inject(APP_ROUTES) private appRoutes,
    private router: Router,
    private userProfileSearcherService: UserProfileGetService,
    private userPutService: UserPutService,
    private appearanceService: AppearanceService,
    private refreshTokenService: RefreshTokenService,
    private sentryScopeService: SentryScopeService
  ) {}

  public logout() {
    this.purgeAuth();
    return this.router.navigate(['login']);
  }

  private setAuth(token: TokenModel) {
    this.jwtService.saveToken(token.token);
    this.refreshTokenService.saveToken(token.refreshToken);
  }

  public purgeAuth() {
    this.token = null;
    this.jwtService.destroyToken();
    this.refreshTokenService.destroyToken();
    this._loggedUserSubject.next(null);
    Sentry.configureScope(scope => scope.setUser(null));
  }

  public sendEmailRequest(credentials: {
    email: string;
  }): Observable<any> | any {
    return this.http
      .post<any>('/users/password', {
        ...credentials,
        appUrl: environment.appUrl,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  public attemptAuth(credentials: {
    username: string;
    password: string;
    code?: string | null;
  }): Observable<TokenModel> {
    return this.tokenPostService.auth(credentials).pipe(
      map(token => {
        if (token.token && token.refreshToken) {
          this.setAuth(token);
        }

        return token;
      })
    );
  }

  public getMe(): Observable<UserModel> {
    return this.userProfileSearcherService.me().pipe(
      catchError((err: any) => {
        this.purgeAuth();
        return of(err);
      }),
      filter(user => user !== null && user instanceof UserModel),
      map(user => {
        this.translate.setActiveLang(user?.getLanguage() || 'en');
        this.setUpUserDefaultSeparator(user);
        this._loggedUserSubject.next(user);
        this.sentryScopeService.monitorUser(user);
        this.appearanceService.init();

        return user;
      })
    );
  }

  private setUpUserDefaultSeparator(user: UserModel): void {
    if (!user.getDecimalsSeparator() || !user.getThousandsSeparator()) {
      const browserLang = getBrowserLang();
      if (browserLang === 'es') {
        user.setUpDefaultSeparators(',', '.');
      } else {
        user.setUpDefaultSeparators('.', ',');
      }
    }
  }

  public updateUser(payload: any): Observable<UserModel> {
    const user = this._loggedUserSubject.value;

    return this.userPutService.put(user.getId(), payload).pipe(
      switchMap(() => {
        return this.getMe();
      })
    );
  }

  public refreshToken(): Observable<TokenModel> {
    return this.tokenPostService
      .refresh(this.refreshTokenService.getToken())
      .pipe(
        map((token: TokenModel) => {
          this.setAuth(token);
          this.getMe();

          return token;
        })
      );
  }
}
